@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.cookies {
    z-index: 2002;
    height: 132px;
    width: 340px;
    position: fixed;
    bottom: 70px;
    left: 70px;
    border-radius: 16px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);

    @media screen and (max-width: $breakpoint-mobile) {
        width: calc(100% - 32px);
        left: 16px;
    }
    @media screen and (max-width: 326px) {
        height: 146px;
    }
}
